import {createClient} from '@sanity/client'
import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: "c97a2r7n",
  dataset: "production",
  apiVersion: "2023-08-23",
  useCdn: true,
  token: process.env.SANITY_TOKEN,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
