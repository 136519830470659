import { Helmet } from "react-helmet";
import Article from "./Article";
import { useEffect, useState } from "react";
import { client } from "../library/client";

const KnowledgeBase = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const data = await client.fetch(
      '*[_type == "knowledge-base"] | order(_createdAt desc)'
    );
    setData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Storlytics | Knowledge Base</title>
      </Helmet>

      <main>
        <section>
          {data.map((article, index) => (
            <Article key={index} {...article} />
          ))}
        </section>
      </main>
    </>
  );
};

export default KnowledgeBase;