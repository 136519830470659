import { useState, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaEnvelope } from "react-icons/fa";
import "../index.css";
import { USERS_API_URL } from "../constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import Select from "react-select";

const QuoteForm = (props) => {
  const [message, setMessage] = useState({
    success: false,
    error: false,
    message: ""
  });
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    company_name: "",
    company_type: "",
    company_employee_number: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    email: "",
    company_name: "",
    company_type: "",
    company_employee_number: "",
    message: "",
  });

  const [companyType, setCompanyType] = useState([
    { label: "Utility", value: "Utility" },
    { label: "Developer", value: "Developer" },
    { label: "Financier", value: "Financier" },
    { label: "Product", value: "Product" },
    { label: "Other", value: "Other" },
  ]);

  const [companyEmployeeNumber, setCompanyEmployeeNumber] = useState([
    { label: "1-100", value: "1-100" },
    { label: "101-500", value: "101-500" },
    { label: "501-1000", value: "501-1000" },
    { label: "1001-2500", value: "1001-2500" },
    { label: "2500+", value: "2500+" },
  ]);

  const redirectPage = (page) => {
    history.push(page);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        if (!value) {
          error = "Please enter your name";
        } else if (value.length < 2 || !/^[\w\s.]+$/.test(value)) {
          error = "Please enter a valid name";
        }
        break;
      case "phone":
        if (!value) {
          error = "Please enter phone number";
        } else if (
          !/^(?:(?:\+|00)(?:\d{1,3})?)?(?:\(\d{1,5}\))?(?:[-.\s]?\d{7,15})+$/.test(
            value
          )
        ) {
          error = "Please enter a valid phone number";
        }
        break;
      case "email":
        if (!value) {
          error = "Please enter an email";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Please enter a valid email address";
        }
        break;
      case "company_name":
        if (!value) error = "Please enter company name";
        break;
      case "company_type":
        if (!value === "") error = "Please select company type";
        break;
      case "company_employee_number":
        if (!value === "") error = "Please select company employee number";
        break;
      default:
        break;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const hasErrors = Object.values(formErrors).some((error) => error !== "");

      if (!hasErrors) {
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "crafter/quote-create/",
          formData
        );

        if (response.status === 201) {
          setMessage({
            success: true,
            error: false,
            message: "Successfully submitted the form. A member of the Storlytics team will contact you shortly.",
          });
          // Clear the form data after successful submission
          setFormData({
            name: "",
            phone: "",
            email: "",
            company_name: "",
            company_type: "",
            company_employee_number: "",
            message: "",
          });
        } else {
          // Handle other response statuses or unexpected response data
          setMessage({
            success: false,
            error: true,
            message:
              "Unable to submit the form. It may be a network issue. Please try again.",
          });
        }
      }
    } catch (error) {
      setMessage({
        success: false,
        error: true,
        message:
          "Unable to submit the form. It may be a network issue. Please try again.",
      });
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Quote for Consulting | Storlytics </title>
      </Helmet>
      <Form onSubmit={handleSubmit}>
        <Row style={{ padding: "0px", margin: "10px 0px 0px 0px" }}>
          <Col
            className="breadcrumb_container"
            style={{ padding: "0 20px 0px 20px", margin: "0px" }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Breadcrumb
              style={{
                margin: "0px",
                padding: "0 10px 10px",
                textAlign: "left",
                fontSize: "0.9rem",
                backgroundColor: "#E7EAED",
              }}
            >
              <Breadcrumb.Item onClick={() => redirectPage("/")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => redirectPage("/energy_storage_consulting")}
              >
                Energy Storage Consulting
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Quote for Consulting</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row style={{ padding: "0px", margin: "0px 0px 0px 0px" }}>
          <Col
            style={{ margin: "0px", padding: "0px" }}
            xs={0}
            sm={0}
            md={0}
            lg={3}
            xl={3}
          ></Col>
          <Col
            style={{ padding: "10px 20px 20px 20px", margin: "0px" }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <Row
              className="shadow-lg"
              style={{
                margin: "0px",
                padding: "0px",
                border: "1px solid lightgray",
              }}
            >
              <Col
                style={{
                  margin: "0px",
                  padding: "7.5px",
                  backgroundColor: "#F7F7F7",
                  borderBottom: "1px solid lightgray",
                }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="float-left">
                  <FaEnvelope className="quote_section_icon" />
                  Quote for Consulting
                </div>
              </Col>
              <Col
                style={{
                  margin: "0px",
                  padding: "0px",
                  borderBottom: "1px solid lightgray",
                }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Card style={{ border: "none" }}>
                  <Card.Body>
                    <Row style={{ padding: "0px", margin: "0px" }}>
                      <Col style={{ padding: "0px" }}>
                        {message.success && <Alert variant="success" style={{textAlign: "left"}}>{message.message}</Alert>}
                        {message.error && <Alert variant="danger" style={{textAlign: "left"}}>{message.message}</Alert>}
                      </Col>
                    </Row>
                    <Card.Text>
                      <Row style={{ margin: "0px", padding: "0px" }}>
                        <Col
                          style={{ margin: "0px", padding: "0px" }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <Row style={{ margin: "0px", padding: "0px" }}>
                            <Col
                              style={{ margin: "0px", padding: "0px" }}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Form.Group controlId="name">
                                <Form.Label className="float-left">
                                  Name<span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                  name="name"
                                  value={formData.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  placeholder="Enter Name"
                                  required
                                />
                                <Row style={{ padding: "0px", margin: "0px" }}>
                                  <Col style={{ padding: "0px" }}>
                                    {formErrors.name &&
                                      formErrors.name.length > 0 && (
                                        <span className="float-left error_message">
                                          {formErrors.name}
                                        </span>
                                      )}
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row style={{ margin: "0px", padding: "0px" }}>
                            <Col
                              style={{ margin: "0px", padding: "0px" }}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Form.Group controlId="phone">
                                <Form.Label className="float-left">
                                  Phone <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                  name="phone"
                                  value={formData.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  placeholder="Enter Phone Number"
                                  required
                                />
                                <Row style={{ padding: "0px", margin: "0px" }}>
                                  <Col style={{ padding: "0px" }}>
                                    {formErrors.phone &&
                                      formErrors.phone.length > 0 && (
                                        <span className="float-left error_message">
                                          {formErrors.phone}
                                        </span>
                                      )}
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row style={{ margin: "0px", padding: "0px" }}>
                            <Col
                              style={{ margin: "0px", padding: "0px" }}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Form.Group controlId="email">
                                <Form.Label className="float-left">
                                  Email address{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text id="email_prepend">
                                      @
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Enter email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                  ></Form.Control>
                                </InputGroup>
                                <Row style={{ padding: "0px", margin: "0px" }}>
                                  <Col style={{ padding: "0px" }}>
                                    {formErrors.email &&
                                      formErrors.email.length > 0 && (
                                        <span className="float-left error_message">
                                          {formErrors.email}
                                        </span>
                                      )}
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row style={{ margin: "0px", padding: "0px" }}>
                            <Col
                              style={{ margin: "0px", padding: "0px" }}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Form.Group controlId="company_name">
                                <Form.Label className="float-left">
                                  Company<span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                  name="company_name"
                                  value={formData.company_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  placeholder="Enter Company Name"
                                  required
                                />
                                <Row style={{ padding: "0px", margin: "0px" }}>
                                  <Col style={{ padding: "0px" }}>
                                    {formErrors.company_name &&
                                      formErrors.company_name.length > 0 && (
                                        <span className="float-left error_message">
                                          {formErrors.company_name}
                                        </span>
                                      )}
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Form.Group controlId="company_type">
                            <Row style={{ margin: "0px", padding: "0px" }}>
                              <Col
                                style={{ margin: "0px", padding: "0px" }}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Form.Label className="float-left">
                                  Company Type{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                              </Col>
                            </Row>
                            <Row style={{ margin: "0px", padding: "0px" }}>
                              <Col
                                style={{ margin: "0px", padding: "0px" }}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Select
                                  value={companyType.filter(
                                    ({ value }) =>
                                      value === formData.company_type
                                  )}
                                  onChange={({ value }) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      company_type: value,
                                    }))
                                  }
                                  placeholder={"Select Company Empoyee"}
                                  isSearchable={true}
                                  name="company_type"
                                  options={companyType}
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      width: "100%",
                                    }),
                                    dropdown: (baseStyles) => ({
                                      ...baseStyles,
                                      width: "100%",
                                    }),
                                    option: (provided, state) => ({
                                      ...provided,
                                      textAlign: "left",
                                    }),
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group controlId="company_employee_number">
                            <Row style={{ margin: "0px", padding: "0px" }}>
                              <Col
                                style={{ margin: "0px", padding: "0px" }}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Form.Label className="float-left">
                                  Select Company Employee Number
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                              </Col>
                            </Row>
                            <Row style={{ margin: "0px", padding: "0px" }}>
                              <Col
                                style={{ margin: "0px", padding: "0px" }}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Select
                                  value={companyEmployeeNumber.filter(
                                    ({ value }) =>
                                      value === formData.company_employee_number
                                  )}
                                  onChange={({ value }) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      company_employee_number: value,
                                    }))
                                  }
                                  placeholder={"Select Company Empoyee Number"}
                                  isSearchable={true}
                                  name="company_employee_number"
                                  options={companyEmployeeNumber}
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      width: "100%",
                                    }),
                                    dropdown: (baseStyles) => ({
                                      ...baseStyles,
                                      width: "100%",
                                    }),
                                    option: (provided, state) => ({
                                      ...provided,
                                      textAlign: "left",
                                    }),
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                          <Row style={{ margin: "0px", padding: "0px" }}>
                            <Col
                              style={{ margin: "0px", padding: "0px" }}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Form.Group controlId="message">
                                <Form.Label className="float-left">
                                  Message
                                </Form.Label>
                                <Form.Control
                                  name="message"
                                  value={formData.message}
                                  onChange={handleChange}
                                  as="textarea"
                                  placeholder="Enter your message"
                                />
                                <Row style={{ padding: "0px", margin: "0px" }}>
                                  <Col style={{ padding: "0px" }}>
                                    {formErrors.message &&
                                      formErrors.message.length > 0 && (
                                        <span className="float-left error_message">
                                          {formErrors.message}
                                        </span>
                                      )}
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row
              className="shadow-lg"
              style={{
                margin: "10px 0px",
                padding: "0px",
                border: "1px solid lightgray",
              }}
            >
              <Col
                style={{
                  margin: "0px",
                  padding: "10px",
                  borderBottom: "1px solid lightgray",
                }}
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Button
                  className="float-left"
                  onClick={() => redirectPage("/energy_storage_consulting")}
                  size="sm"
                  variant="primary"
                >
                  Back
                </Button>
              </Col>
              <Col
                style={{
                  margin: "0px",
                  padding: "10px",
                  borderBottom: "1px solid lightgray",
                }}
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Button
                  type="submit"
                  className="float-right"
                  size="sm"
                  variant="success"
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default withRouter(QuoteForm);
