import './App.css';
import CustomRouter from "./router";
import Header from './components/Header'
import Footer from './components/Footer'
import ReactGa from 'react-ga'
import { useEffect } from 'react';

function App() {
  useEffect(() =>{
    ReactGa.initialize('UA-202975781-1')
    //to report page view
    ReactGa.pageview(window.location.pathname+window.location.search)
  },[])

  return (
    <div className="App">
        <Header/>
          <CustomRouter/>
        <Footer/>
    </div>
  );
}

export default App;
