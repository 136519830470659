import { SIGN_IN, SIGN_OUT, CLEAR_SESSION, USER_CREATED_SUCCESS, USER_DATA, CART_DATA, LICENSE_DATA, ORDER_DATA, RENEWAL } from "./sessionTypes";

const initialState = {
  isLoggedIn: false,
  userCreatedSuccess: { is_created: false, message_type: "" },
  userData: {},
  cartData: {},
  licenseData: [],
  orderData: [],
  renewal: {},
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case SIGN_OUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    case CLEAR_SESSION:
      return {
        isLoggedIn: false,
        userCreatedSuccess: { is_created: false, message_type: "" },
        userData: {},
        cartData: {},
        licenseData: [],
        orderData: [],
        renewal: {},
      };
    case USER_CREATED_SUCCESS:
      return {
        ...state,
        userCreatedSuccess: { is_created: true, message_type: action.payload },
      };
    case USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case CART_DATA:
      return {
        ...state,
        cartData: action.payload,
      };
    case LICENSE_DATA:
      return {
        ...state,
        licenseData: action.payload,
      };
    case ORDER_DATA:
      return {
        ...state,
        orderData: action.payload,
      };
    case RENEWAL:
      return {
        ...state,
        renewal: action.payload,
      };
    default:
      return state;
  }
};

export default sessionReducer;
