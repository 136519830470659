import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

const EditKnowledgeBase = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const id = pathname
    .substring(pathname.lastIndexOf("/") + 1)
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");

  const [formData, setFormData] = useState({
    image: null,
    author: "",
    title: "",
    description: "",
    details: "",
  });

  const [updateFormData, setUpdateFormData] = useState({
    image: null,
    author: "",
    title: "",
    description: "",
    details: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `crafter/get-knowledge-base/${id}/`
      );
      setFormData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;

    if (type === "file") {
      setUpdateFormData((prevFormData) => ({
        ...prevFormData,
        image: files[0],
      }));
    } else {
      setUpdateFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    const formPayload = new FormData();
    if (updateFormData.image) {
      formPayload.append("image", updateFormData.image);
    }

    if (updateFormData.author.trim() !== "") {
      formPayload.append("author", updateFormData.author);
    }
    if (updateFormData.title.trim() !== "") {
      formPayload.append("title", updateFormData.title);
    }
    if (updateFormData.description.trim() !== "") {
      formPayload.append("description", updateFormData.description);
    }
    if (updateFormData.details.trim() !== "") {
      formPayload.append("details", updateFormData.details);
    }

    try {
      const response = await axios.put(
        process.env.REACT_APP_BASE_URL + `crafter/knowledge-base-edit/${id}/`,
        formPayload,
        {
          headers: {
            Authorization: "JWT " + window.localStorage.getItem("accessToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Update successful!");

      history.push("/admin/dashboard");
    } catch (error) {
      console.error("Error updating knowledge base:", error);
    }
  };

  return (
    <Main>
      <Form onSubmit={handleUpdate}>
        <Form.Group>
          <Form.Group className="mb-3" controlId="image">
            {!updateFormData.image && formData.image && (
              <img
                src={process.env.REACT_APP_HOST_URL + formData.image}
                alt="Image"
              />
            )}
            {updateFormData.image && (
              <img
                src={URL.createObjectURL(updateFormData.image)}
                alt="Image"
              />
            )}
            <Form.Control name="image" type="file" onChange={handleChange} />
          </Form.Group>
          <Button variant="info" size="md" onClick={handleUpdate}>
            Save
          </Button>
        </Form.Group>
        <Form.Group className="mb-3" controlId="author">
          <Form.Label>Author</Form.Label>
          <Form.Control
            name="author"
            type="text"
            value={updateFormData.author || formData.author}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            type="text"
            value={updateFormData.title || formData.title}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            name="description"
            type="text"
            value={updateFormData.description || formData.description}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="details">
          <Form.Label>Details</Form.Label>
          <Form.Control
            name="details"
            as="textarea"
            rows={8}
            value={updateFormData.details || formData.details}
            onChange={handleChange}
          />
        </Form.Group>
      </Form>
    </Main>
  );
};

export default EditKnowledgeBase;

const Main = styled.main`
  padding: 0 128px;
  text-align: left;

  img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
  }

  form {
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        align-items: center;
        gap: 32px;
      }
    }
  }
`;
