export const SIGN_IN = "SIGN_IN";

export const SIGN_OUT = "SIGN_OUT";

export const CLEAR_SESSION = "CLEAR_SESSION";

export const USER_CREATED_SUCCESS = "USER_CREATED_SUCCESS";

export const USER_DATA = "USER_DATA";

export const CART_DATA = "CART_DATA";

export const LICENSE_DATA = "LICENSE_DATA";

export const ORDER_DATA = "ORDER_DATA";

export const RENEWAL = "RENEWAL"