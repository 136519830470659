import {
  SIGN_IN,
  SIGN_OUT,
  CLEAR_SESSION,
  USER_CREATED_SUCCESS,
  USER_DATA,
  CART_DATA,
  LICENSE_DATA,
  ORDER_DATA,
  RENEWAL
} from "./sessionTypes";

export const sign_in = () => {
  return {
    type: SIGN_IN,
  };
};

export const sign_out = () => {
  return {
    type: SIGN_OUT,
  };
};

export const clear_session = () => {
  return {
    type: CLEAR_SESSION,
  };
};

export const user_created_success = (message_type='') => {
  return {
    type: USER_CREATED_SUCCESS,
    payload: message_type
  };
};

export const user_data = (user_data = {}) => {
  return {
    type: USER_DATA,
    payload: user_data,
  };
};

export const cart_data = (cart = {}) => {
  return {
    type: CART_DATA,
    payload: cart,
  };
};

export const license_data = (licenses = []) => {
  return {
    type: LICENSE_DATA,
    payload: licenses,
  };
};

export const order_data = (orders = []) => {
  return {
    type: ORDER_DATA,
    payload: orders,
  };
};

export const renewal = (renewal = {}) => {
  return {
    type: RENEWAL,
    payload: renewal,
  };
};