import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Col, Dropdown, Nav, Navbar, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter, Link } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { clear_session } from "../redux";
import logo from "../static/images/storlytics_white.png"

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const session_is_auth = useSelector((state) => state.session.isLoggedIn);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    $("a").on("click", function () {
      $("a").css("color", "white");
      $("a").not(this).removeClass("underline");
      $(this).toggleClass("underline");
    });
  }, []);

  useEffect(() => {
    if (session_is_auth) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [session_is_auth]);

  useEffect(() => {
    let link_dict = {
      "/shop/login": "Login",
      "/": "Home",
      "/download": "Download",
      "/energy_storage_software": "Energy Storage Software",
      "/shop": "Shop",
      "/admin": "Admin Dashboard",
      "/contact_us": "Contact Us",
      "/about_us": "About Us",
      "/knowledge_base": "Knowledge Base",
      "/energy_storage_consulting": "Energy Storage Consulting",
    };
    let links = Array.from(document.getElementsByClassName("nav_links"));
    links.forEach((item) => {
      if (link_dict[history.location.pathname] === item.innerHTML) {
        item.style.color = "white";
        item.classList.add("underline");
      } else {
        item.classList.remove("underline");
      }
    });
  }, [history.location]);

  const redirectPage = (page) => {
    history.push(page);
  };

  const logout = () => {
    axiosInstance
      .get("users/logout/")
      .then((response) => {
        if (response.data.ok) {
          dispatch(clear_session());
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          history.push("/shop/login");
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Navbar
      className="sticky-top navbar-dark "
      collapseOnSelect
      expand="lg"
      style={{ background: " #04ac4e" }}
    >
      <Navbar.Brand
        style={{ cursor: "pointer" }}
        onClick={() => redirectPage(isAuth ? "/home" : "/")}
      >
        <Row style={{ margin: "0px", padding: "0px" }}>
          <Col
            style={{ margin: "0px 0px 0px 0px", padding: "0px" }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <img
              src={logo}
              width="300"
              height="80"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Col>
          {/* <Col style={{ margin: '0px', padding: '0px' }} xs={9} sm={9} md={9} lg={9} xl={9}>
                        <p className="brand_name" >Storlytics</p>
                        <p className="brand_tag_line" >Energy Storage Modeling Made Simple!</p>
                    </Col> */}
        </Row>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="nav_link_container ml-auto">
          {isAuth ? (
            <Nav.Link className="nav_links" onClick={() => logout()}>
              Logout
            </Nav.Link>
          ) : (
            <>
              <Nav.Link className="nav_links" onClick={() => redirectPage("/")}>
                Home
              </Nav.Link>
              <Nav.Link  onClick={() => redirectPage("/knowledge_base")} className="nav_links">
                Knowledge Base
              </Nav.Link>
              <Nav.Link onClick={() => redirectPage("/energy_storage_software")} className="nav_links">
                Energy Storage Software
              </Nav.Link>
              <Nav.Link onClick={() => redirectPage("/download")} className="nav_links">
                Download
              </Nav.Link>
              <Nav.Link onClick={() => redirectPage("/energy_storage_consulting")} className="nav_links">
                Energy Storage Consulting
              </Nav.Link>

              <Nav.Link onClick={() => redirectPage("/contact_us")} className="nav_links">
                Contact Us
              </Nav.Link>

              <Nav.Link onClick={() => redirectPage("/about_us")} className="nav_links">
                About Us
              </Nav.Link>
              <Nav.Link onClick={() => redirectPage("/shop/login")} className="nav_links">
                Login
              </Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default withRouter(Header);
