import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { urlFor } from "../library/client";

const Article = ({ author, image, title, description, slug }) => {
  const history = useHistory();

  return (
    <Card onClick={() => history.push(`/knowledge_base/${slug.current}`)}>
      <div>
        <img src={urlFor(image.asset._ref).url()} />

        <div>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    </Card>
  );
};

export default Article;

const Card = styled.article`
  padding: 32px;
  border: 1px solid #00000020;
  border-radius: 10px;
  cursor: pointer;
  transition: scale 0.2s ease;

  > div {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  img {
    width: 128px;
    height: 128px;
  }

  &:hover {
    scale: 1.01;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin: 0 0 16px;
  }

  p {
    text-align: left;
    font-size: 18px;
    white-space: pre-wrap;
  }

  > p {
    margin: 16px 0 0;
  }

  button {
    color: #04ac4e;
    background: none;
    border: 0;
  }

  @media (width > 992px) {
    margin: 32px 128px;
  }
`;
